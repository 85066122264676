import { assignInlineVars } from '@vanilla-extract/dynamic';
import { Box, Inline, Heading, ButtonLink, Text } from 'braid-design-system';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import type { BannerProps } from '../HomePageBanner';

import * as styles from './GenericAsiaBanner.css';

const GenericAsiaBannerSection = ({
  text: { title, subTitle, inverted },
  background,
  cta,
  location,
}: BannerProps) => {
  const analyticsFacade = useAnalyticsFacade();

  return (
    <Box
      height="full"
      borderRadius="xlarge"
      overflow="hidden"
      background={inverted ? 'customDark' : undefined}
      className={[styles.background, styles.responsive]}
      style={assignInlineVars({
        [styles.backgroundColour]: background.color,
        [styles.backgroundImage]: `url('${background.imageUrl}')`,
      })}
    >
      <Box display="flex" height="full" className={styles.containerLayout}>
        <Box flexGrow={1} minWidth={0} width="full">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="full"
            className={styles.contentGutter}
          >
            <Heading weight="regular" level="3">
              <Box display="block" className={styles.title}>
                {title}
              </Box>
            </Heading>
            {subTitle && subTitle.label ? (
              <Text size="large">
                <Box display="block" className={styles.subTitle}>
                  {subTitle.label}
                </Box>
              </Text>
            ) : null}
            {cta && location ? (
              <Box
                paddingTop="gutter"
                background={cta.inverted ? 'customDark' : undefined}
              >
                <Inline space="none">
                  <ButtonLink
                    variant={cta?.variant}
                    tone={cta?.tone}
                    href={location}
                    onClick={() => {
                      analyticsFacade.homepageMarketingBannerClicked({
                        linkContext: {
                          linkAction: cta.linkContext?.linkAction,
                          linkText: cta.linkContext?.linkText,
                        },
                      });
                    }}
                  >
                    <span className={styles.nowrap}>{cta.label}</span>
                  </ButtonLink>
                </Inline>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box
          flexGrow={1}
          minWidth={0}
          width="full"
          display="flex"
          justifyContent="center"
          alignItems="flexEnd"
          paddingRight={background.centerImage ? { wide: 'small' } : undefined}
          paddingBottom={
            background.centerImage
              ? { mobile: 'small', wide: 'none' }
              : undefined
          }
        >
          <Box
            className={[
              styles.image,
              {
                // Center the image if we want to show the full image
                [styles.centerImage]: background.centerImage,
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GenericAsiaBannerSection;
