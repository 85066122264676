import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2SwXKCMBCG7zxFjuUQB0FR8NI36cSwQiokTIhC7fjuHVLpmBWkvfcG+b+F7Le7eFuqoGvyS0Y+PUIOJXQ0Exq4EUqm5Mz0C6UDs/Z33tVbDK9gS2qWZULm1Kga87G/uwP2yhhVYWbjMCUcDCa2DqFFXjwgiXuxg71YAWMos1+rhKTj+d7mrchMgSNuoz3jx1yrk8yoqFgOmFpiqhEXSAlXZ9Ao0VADMymR6vaI8lo1YmwOodtubtu9q+OqVBoXBW5RMTe8yOXFJJ+wc6lPCH+3eFsIA7SpGYe+y1az2qGOlrpbsN5TearkzjlmKYmCoO7cU56SMF7h0w26V+C7eTyTb2fy5HnfJZ7FMH1pmJAT86UfKeEgTb8gV++1gkww0nANIAmTGXmpWEdvK7nuRfj2L0jjpMgplVMyn+mMfEyMC51X+kzqN3GdkCHkIGOz+pfxIyNJwr/KmP/oMvztvmnVjvlNolG/0Sqe9xvP+n0ksN9HAvuNB79fYuiSiwwHAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var centerImage = '_1o0xsgzl';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzj';
export var responsive = '_1o0xsgzk';
export var subTitle = '_1o0xsgzi';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';