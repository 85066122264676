import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  HiddenVisually,
  Stack,
  Text,
} from 'braid-design-system';
import { memo } from 'react';

import { useBrandName } from 'src/hooks/useBrandName';
import { useLoggedOutHomepageUpliftFeatureFlag } from 'src/store/featureFlags/hooks.ts';

import translations from './.vocab';
import { PromoteSignInSection } from './PromoteSignInSection/PromoteSignInSection';
import RegisterButton from './RegisterButton.tsx';
import SignInButton from './SignInButton.tsx';

const SignedOutDashboard = () => {
  const { t } = useTranslations(translations);
  const brandName = useBrandName();
  const loggedOutHomepageUpliftFeatureFlag =
    useLoggedOutHomepageUpliftFeatureFlag();

  switch (loggedOutHomepageUpliftFeatureFlag) {
    case 'with-selling-points':
    case 'with-multi-ctas':
      return <PromoteSignInSection type={loggedOutHomepageUpliftFeatureFlag} />;
  }

  return (
    <Box
      component="nav"
      aria-labelledby="SignedOutDashboardHeading"
      padding="gutter"
      paddingY={{ mobile: 'gutter', tablet: 'xlarge' }}
      background="neutralLight"
      borderRadius="xlarge"
      data={{ automation: 'signedOutDashboard' }}
    >
      <HiddenVisually>
        <h1 id="SignedOutDashboardHeading">{t('Sign in or register')}</h1>
      </HiddenVisually>

      <Stack space="medium" align="center">
        <Box width="full" maxWidth="xsmall">
          <Columns space="small" alignY="center">
            <Column>
              <SignInButton />
            </Column>
            <Column>
              <RegisterButton />
            </Column>
          </Columns>
        </Box>
        <Text align={{ tablet: 'center' }}>
          {t(
            'Sign in to manage your {brand} Profile, save searches and view your recommended jobs.',
            { brand: brandName },
          )}
        </Text>
      </Stack>
    </Box>
  );
};

export default memo(SignedOutDashboard);
