import {
  Box,
  Button,
  Heading,
  IconArrow,
  IconTick,
  List,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import type { LoggedOutHomepageUpliftExperiment } from 'src/config/experiments';

import * as styleRefs from './PromoteSignInSection.css';

const PromoteSignInSectionView = ({
  header,
  content,
  imageSrc,
}: {
  header: string | ReactNode;
  content: ReactNode;
  imageSrc: string;
}) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection={{ mobile: 'column', tablet: 'row' }}
    position="relative"
    gap="large"
    className={styleRefs.rootContainer}
  >
    <Box
      background="surface"
      borderRadius="large"
      paddingY="large"
      paddingX={{ mobile: 'xsmall', tablet: 'large' }}
      display="flex"
      flexDirection="column"
      gap="large"
      zIndex={1}
      className={styleRefs.widgetContainer}
      position="relative"
    >
      {/* This workaround leverages the `boxShadow` property on a Box component instead of defining it through CSS. 
          Need to ensures the `borderRadius` matches that of the parent container for consistent styling. */}
      <Box
        position="absolute"
        boxShadow="large"
        borderRadius="large"
        width="full"
        height="full"
        left={0}
        top={0}
        display={{ mobile: 'none', tablet: 'block' }}
      />
      <Heading level="2">{header}</Heading>
      {content}
    </Box>
    <Box
      overflow="hidden"
      position={{ mobile: 'relative', tablet: 'absolute' }}
      right={0}
      height="full"
      borderRadius="large"
      className={styleRefs.imageContainer}
    >
      <img src={imageSrc} className={styleRefs.image} />
    </Box>
  </Box>
);

export const PromoteSignInSection = ({
  type,
}: {
  type: Extract<
    LoggedOutHomepageUpliftExperiment,
    'with-selling-points' | 'with-multi-ctas'
  >;
}) => (
  <PromoteSignInSectionView
    header={
      <>
        <Strong>{type}:</Strong> Looking for the right job? You’re more likely
        to find it on SEEK.
      </>
    }
    content={
      <>
        <Stack space="small">
          <Text>Sign in for better job matches: </Text>
          <List type="icon" space="small" icon={<IconTick tone="positive" />}>
            <Text>Smarter search to find all the right jobs</Text>
            <Text>Job recommendations personalised to you</Text>
            <Text>Instant job alerts so you never miss an opportunity</Text>
          </List>
        </Stack>
        <Box style={{ alignSelf: 'start' }}>
          <Button tone="formAccent">
            Get started <IconArrow direction="right" />
          </Button>
        </Box>
      </>
    }
    imageSrc="https://as2.ftcdn.net/v2/jpg/05/88/41/59/1000_F_588415935_gK8oRmHx8sHuv56IJPmdjUVD8I3tA715.jpg"
  />
);
