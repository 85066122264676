
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJSZWZpbmVkIGZvciB5b3UiOiJSZWZpbmVkIGZvciB5b3UiLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIiwiQmV0YSI6IkJldGEiLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiLCJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IjoiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rcyBmb3Igc2hhcmluZyI6IlRoYW5rcyBmb3Igc2hhcmluZyIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIiwiVGhhdOKAmXMgaGVscGZ1bCI6IlRoYXTigJlzIGhlbHBmdWwiLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJbxbTEg8SDxIPguIHguLXguYnhua8gw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaE%2FXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIkJldGEiOiJb4bqe4bq94bq94bmvxIPEg10iLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJbxYHhur3hur3hur3hua8gbcyC4bq94bq94bq9IMWh4bq94bq94bq94bq94bq94bq9IMOsw6zDrMaSIMOPJ%2BG5veG6veG6veG6vSDEo8O2w7bDtuG5ryDhua%2FhuKnDrMOsw6zFoSDFmcOsw6zDrMSj4bip4bmvXSIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJbx6bFmeG6veG6veG6vcSDxIPEg%2BG5ryEgxpHDrMOsw6zguIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIOG5r%2BG4qcO2w7bDtsWh4bq94bq94bq9IMWZw7bDtsO2xprhur3hur3hur3FoSDGksO2w7bDtsWZIMO9w73DvcO2w7bDtseax5rHml0iLCJHb29kIHRvIGtub3ciOiJbx6bDtsO2w7bDtsO2w7bGjCDhua%2FDtsO2w7YgxLfguIHguLXguYnDtsO2w7bFtV0iLCJUaGFua3MgZm9yIHNoYXJpbmciOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSDGksO2w7bDtsWZIMWh4bipxIPEg8SDxZnDrMOsw6zguIHguLXguYnEo10iLCJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIjoiW8W0w7bDtsO2xZnEt8Osw6zDrOC4geC4teC5icSjIMO2w7bDtuC4geC4teC5iSDhua%2FhuKnhur3hur3hur0gxZnDrMOsw6zEo%2BG4qeG5ryBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlRoYXTigJlzIGhlbHBmdWwiOiJb4bmu4bipxIPEg8SD4bmv4oCZxaEg4bip4bq94bq94bq9xprGpcaSx5rHmseaxppdIiwiTG9va2luZyBhdCB5b3VyIGpvYiBtYXRjaGVzIjoiW8WBw7bDtsO2w7bDtsO2xLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8gw73DvcO9w7bDtsO2x5rHmseaxZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJbxbTEg8SDxIPguIHguLXguYnhua8gw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaE%2FXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIkJldGEiOiJb4bqe4bq94bq94bmvxIPEg10iLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJbxYHhur3hur3hur3hua8gbcyC4bq94bq94bq9IMWh4bq94bq94bq94bq94bq94bq9IMOsw6zDrMaSIMOPJ%2BG5veG6veG6veG6vSDEo8O2w7bDtuG5ryDhua%2FhuKnDrMOsw6zFoSDFmcOsw6zDrMSj4bip4bmvXSIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJbx6bFmeG6veG6veG6vcSDxIPEg%2BG5ryEgxpHDrMOsw6zguIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIOG5r%2BG4qcO2w7bDtsWh4bq94bq94bq9IMWZw7bDtsO2xprhur3hur3hur3FoSDGksO2w7bDtsWZIMO9w73DvcO2w7bDtseax5rHml0iLCJHb29kIHRvIGtub3ciOiJbx6bDtsO2w7bDtsO2w7bGjCDhua%2FDtsO2w7YgxLfguIHguLXguYnDtsO2w7bFtV0iLCJUaGFua3MgZm9yIHNoYXJpbmciOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSDGksO2w7bDtsWZIMWh4bipxIPEg8SDxZnDrMOsw6zguIHguLXguYnEo10iLCJXb3JraW5nIG9uIHRoZSByaWdodCBtYXRjaGVzIjoiW8W0w7bDtsO2xZnEt8Osw6zDrOC4geC4teC5icSjIMO2w7bDtuC4geC4teC5iSDhua%2FhuKnhur3hur3hur0gxZnDrMOsw6zEo%2BG4qeG5ryBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlRoYXTigJlzIGhlbHBmdWwiOiJb4bmu4bipxIPEg8SD4bmv4oCZxaEg4bip4bq94bq94bq9xprGpcaSx5rHmseaxppdIiwiTG9va2luZyBhdCB5b3VyIGpvYiBtYXRjaGVzIjoiW8WBw7bDtsO2w7bDtsO2xLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8gw73DvcO9w7bDtsO2x5rHmseaxZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSJ9!"
        )
      )
      });
  
      export { translations as default };
    