
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiVGVsbCBzZWVrQWkgd2hhdCB5b3UncmUgbG9va2luZyBmb3Igc28gd2UgY2FuIHN1Z2dlc3QgbW9yZSByZWxldmFudCBqb2JzLiIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJSZWZpbmUgcmVjb21tZW5kYXRpb25zIiwiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uLi4uIjoiRG9u4oCZdCBzaGFyZSBzZW5zaXRpdmUgaW5mb3JtYXRpb24uIEJ5IHByb3ZpZGluZyB5b3VyIHBlcnNvbmFsIGluZm9ybWF0aW9uLCB5b3UgYWdyZWUgdG8gdGhlIDxDb2xsZWN0aW9uTm90aWNlTGluaz5Db2xsZWN0aW9uIE5vdGljZTwvQ29sbGVjdGlvbk5vdGljZUxpbms%2BIGFuZCA8UHJpdmFjeUxpbms%2BUHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lMaW5rPi4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiW%2BG5ruG6veG6veG6vcaaxpogxaHhur3hur3hur3hur3hur3hur3Et%2BG6rMOsw6zDrCDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5onxZnhur3hur3hur0gxprDtsO2w7bDtsO2w7bEt8Osw6zDrOC4geC4teC5icSjIMaSw7bDtsO2xZkgxaHDtsO2w7YgxbXhur3hur3hur0gw6fEg8SDxIPguIHguLXguYkgxaHHmseax5rEo8Sj4bq94bq94bq9xaHhua8gbcyCw7bDtsO2xZnhur3hur3hur0gxZnhur3hur3hur3GmuG6veG6veG6veG5vcSDxIPEg%2BC4geC4teC5ieG5ryDEtcO2w7bDtsOfxaEuXSIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJbxZjhur3hur3GksOsw6zguIHguLXguYnhur3hur0gxZnhur3hur3Dp8O2w7ZtzIJtzILhur3hur3guIHguLXguYnGjMSDxIPhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIkRvbuKAmXQgc2hhcmUgc2Vuc2l0aXZlIGluZm9ybWF0aW9uLi4uLiI6IlvhuIrDtsO2w7bguIHguLXguYnigJnhua8gxaHhuKnEg8SDxIPFmeG6veG6veG6vSDFoeG6veG6veG6veC4geC4teC5icWhw6zDrMOs4bmvw6zDrMOs4bm94bq94bq94bq9IMOsw6zDrOC4geC4teC5icaSw7bDtsO2xZltzILEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkuIOG6nsO9w73DvSDGpcWZw7bDtsO24bm9w6zDrMOsxozDrMOsw6zguIHguLXguYnEoyDDvcO9w73DtsO2w7bHmseax5rFmSDGpeG6veG6veG6vcWZxaHDtsO2w7bguIHguLXguYnEg8SDxIPGmiDDrMOsw6zguIHguLXguYnGksO2w7bDtsWZbcyCxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJLCDDvcO9w73DtsO2w7bHmseax5ogxIPEg8SDxKPFmeG6veG6veG6veG6veG6veG6vSDhua%2FDtsO2w7Yg4bmv4bip4bq94bq94bq9IDxDb2xsZWN0aW9uTm90aWNlTGluaz7Dh8O2w7bGmsaa4bq94bq9w6fhua%2FDrMOsw7bDtuC4geC4teC5iSDDkcO2w7bhua%2FDrMOsw6fhur3hur08L0NvbGxlY3Rpb25Ob3RpY2VMaW5rPiDEg8SDxIPguIHguLXguYnGjCA8UHJpdmFjeUxpbms%2BxqTFmcOsw6zhub3Eg8SDw6fDvcO9IMakw7bDtsaaw6zDrMOnw73DvTwvUHJpdmFjeUxpbms%2BLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUZWxsIHNlZWtBaSB3aGF0IHlvdSdyZSBsb29raW5nIGZvciBzbyB3ZSBjYW4gc3VnZ2VzdCBtb3JlIHJlbGV2YW50IGpvYnMuIjoiW%2BG5ruG6veG6veG6vcaaxpogxaHhur3hur3hur3hur3hur3hur3Et%2BG6rMOsw6zDrCDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5onxZnhur3hur3hur0gxprDtsO2w7bDtsO2w7bEt8Osw6zDrOC4geC4teC5icSjIMaSw7bDtsO2xZkgxaHDtsO2w7YgxbXhur3hur3hur0gw6fEg8SDxIPguIHguLXguYkgxaHHmseax5rEo8Sj4bq94bq94bq9xaHhua8gbcyCw7bDtsO2xZnhur3hur3hur0gxZnhur3hur3hur3GmuG6veG6veG6veG5vcSDxIPEg%2BC4geC4teC5ieG5ryDEtcO2w7bDtsOfxaEuXSIsIlJlZmluZSByZWNvbW1lbmRhdGlvbnMiOiJbxZjhur3hur3GksOsw6zguIHguLXguYnhur3hur0gxZnhur3hur3Dp8O2w7ZtzIJtzILhur3hur3guIHguLXguYnGjMSDxIPhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIkRvbuKAmXQgc2hhcmUgc2Vuc2l0aXZlIGluZm9ybWF0aW9uLi4uLiI6IlvhuIrDtsO2w7bguIHguLXguYnigJnhua8gxaHhuKnEg8SDxIPFmeG6veG6veG6vSDFoeG6veG6veG6veC4geC4teC5icWhw6zDrMOs4bmvw6zDrMOs4bm94bq94bq94bq9IMOsw6zDrOC4geC4teC5icaSw7bDtsO2xZltzILEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkuIOG6nsO9w73DvSDGpcWZw7bDtsO24bm9w6zDrMOsxozDrMOsw6zguIHguLXguYnEoyDDvcO9w73DtsO2w7bHmseax5rFmSDGpeG6veG6veG6vcWZxaHDtsO2w7bguIHguLXguYnEg8SDxIPGmiDDrMOsw6zguIHguLXguYnGksO2w7bDtsWZbcyCxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJLCDDvcO9w73DtsO2w7bHmseax5ogxIPEg8SDxKPFmeG6veG6veG6veG6veG6veG6vSDhua%2FDtsO2w7Yg4bmv4bip4bq94bq94bq9IDxDb2xsZWN0aW9uTm90aWNlTGluaz7Dh8O2w7bGmsaa4bq94bq9w6fhua%2FDrMOsw7bDtuC4geC4teC5iSDDkcO2w7bhua%2FDrMOsw6fhur3hur08L0NvbGxlY3Rpb25Ob3RpY2VMaW5rPiDEg8SDxIPguIHguLXguYnGjCA8UHJpdmFjeUxpbms%2BxqTFmcOsw6zhub3Eg8SDw6fDvcO9IMakw7bDtsaaw6zDrMOnw73DvTwvUHJpdmFjeUxpbms%2BLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    