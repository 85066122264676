import { Column, Columns } from 'braid-design-system';

import GenericAsiaBannerSection from './GenericAsiaBannerSection';
import useGenericAsiaMarketingContent from './useGenericAsiaMarketingContent';

const GenericAsiaBanner = () => {
  const [candidateBannerProps, rhsBannerProps] =
    useGenericAsiaMarketingContent();

  return (
    <Columns
      collapseBelow="tablet"
      space={{
        mobile: 'small',
        tablet: 'small',
        desktop: 'medium',
        wide: 'medium',
      }}
    >
      <Column>
        <GenericAsiaBannerSection {...candidateBannerProps} />
      </Column>
      <Column>
        <GenericAsiaBannerSection {...rhsBannerProps} />
      </Column>
    </Columns>
  );
};

export default GenericAsiaBanner;
